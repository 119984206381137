import { makeAutoObservable } from "mobx";

export default class UserState {
  accessType = "";
  client = "";
  defaultFilters = {};
  filtersSets = {};
  isAuthenticated = false;
  isLoading = true;
  inboxes = [];
  roles = [];
  token = null;
  user = null;
  userId = null;

  constructor() {
    makeAutoObservable(this);
  }

  setAccessType(value) {
    this.accessType = value;
  }

  setClient(value) {
    this.client = value;
  }

  setDefaultFilters(value) {
    this.defaultFilters = value;
  }

  setFiltersSets(value) {
    this.filtersSets = value;
  }

  setIsLoading(value) {
    this.isLoading = value;
  }

  setIsAuthenticated(value) {
    this.isAuthenticated = value;
  }

  setInboxes(value) {
    this.inboxes = value;
  }

  setRoles(value) {
    this.roles = value;
  }

  setToken(value) {
    this.token = value;
  }

  setUser(value) {
    this.user = value;
  }

  setUserId(value) {
    this.userId = value;
  }
}
