import { isEqual } from "lodash";
import { makeAutoObservable, remove, set, entries, toJS } from "mobx";
import { computedFn } from "mobx-utils";

export default class OrganizationsState {
  //UI RELATED
  gridView;
  currentWidget;
  apiStatus;
  firstRun;
  mode;
  //PAGINATION
  offset;
  total;
  size;
  //ORGANIZATIONS
  organizations;
  //SCORES
  scores;
  //SEARCH
  name;
  nameFlag;
  //ORDER
  orderedBy;
  order;
  //FOUNDED_ON
  foundedOn;
  foundedOnGT;
  foundedOnLT;
  //KEYWORDS
  keywords;
  terms;
  termsOperator;
  //INVESTMENT_TYPE
  investmentType;
  investmentTypes;
  //INVESTMENT_AMOUNT
  investmentAmount;
  investmentAmountGT;
  investmentAmountLT;
  investmentAmountDaysGT;
  investmentAmountDaysLT;
  //TRAFFIC
  traffic;
  trafficBetaGT;
  trafficMagnitudeGT;
  //CLASSIFICATION
  classification;
  classifications;
  //CLASSIFICATION_NOT
  classificationNot;
  classificationsNot;
  //TRAFFIC_CLASSIFICATION
  trafficClassification;
  trafficClassifications;
  //HAS_PREDICTIONS
  hasPredictions;
  hasPredictionsValue;
  //HAS_MISSING_DATA
  hasMissingData;
  hasMissingDataValue;
  //REQUIRES_MANUAL_HELP
  requiresManualHelp;
  requiresManualHelpValue;
  //IS_CLOSED
  isClosed;
  isClosedValue;
  //IS_ZOMBIE
  isZombie;
  isZombieValue;
  //REQUIRES_MORE_INFO
  requiresMoreInfo;
  requiresMoreInfoValue;
  //MAKE_CONTACT_REQUIRES_ACTION
  makeContactRequiresAction;
  makeContactRequiresActionValue;
  //UNABLE_TO_CONTACT_REQUIRES_ACTION
  unableToContactRequiresAction;
  unableToContactRequiresActionValue;
  //SOURCE
  source;
  sourceValue;
  //STAGE
  stage;
  stageValue;
  //LOCATION
  location;
  locationContinent;
  locationCity;
  //STATE
  statePartner;
  statePartnerValue;
  stateUser;
  stateUserValue;
  stateUserUser;
  //PEOPLE
  previousExits;
  previousExitsValue;
  daysTopTech;
  daysTopTechValue;
  //FOUNDED_ON
  createdAt;
  createdAtGT;
  createdAtLT;
  //FOUNDED_ON
  enteredStage;
  enteredStageGT;
  enteredStageLT;
  //ASSIGNED_TO
  assignedTo;
  assignedToValue;
  //ASSIGNED_TO
  organizationsSimilarTo;
  organizationsSimilarToValue;
  organizationsSimilarToName;

  constructor(defaultValues) {
    Object.assign(this, defaultValues);
    makeAutoObservable(this);
  }

  setOrganizationsSimilarTo(value) {
    this.organizationsSimilarTo = value;
  }

  setOrganizationsSimilarToValue(value) {
    this.organizationsSimilarToValue = value;
    this.setOrderedBy("score");
  }

  setOrganizationsSimilarToName(value) {
    this.organizationsSimilarToName = value;
  }

  setAssignedTo(value) {
    this.assignedTo = value;
  }

  setAssignedToValue(value) {
    this.assignedToValue = value;
  }

  setCreatedAt(value) {
    this.createdAt = value;
  }

  setCreatedAtGT(value) {
    this.createdAtGT = value;
  }

  setCreatedAtLT(value) {
    this.createdAtLT = value;
  }

  setEnteredStage(value) {
    this.enteredStage = value;
  }

  setEnteredStageGT(value) {
    this.enteredStageGT = value;
  }

  setEnteredStageLT(value) {
    this.enteredStageLT = value;
  }

  setPreviousExits(value) {
    this.previousExits = value;
  }

  setPreviousExitsValue(value) {
    this.previousExitsValue = value;
  }

  setDaysTopTech(value) {
    this.daysTopTech = value;
  }

  setDaysTopTechValue(value) {
    this.daysTopTechValue = value;
  }

  setFirstRun(value) {
    this.firstRun = value;
  }

  setStatePartner(value) {
    this.statePartner = value;
  }

  setStatePartnerValue(value) {
    this.statePartnerValue = value;
  }

  setStateUser(value) {
    this.stateUser = value;
  }

  setStateUserValue(value) {
    this.stateUserValue = value;
  }

  setStateUserUser(value) {
    this.stateUserUser = value;
  }

  setLocation(value) {
    this.location = value;
  }

  setLocationContinent(value) {
    this.locationContinent = value;
  }

  setLocationCountry(value) {
    this.locationCountry = value;
  }

  setLocationCity(value) {
    this.locationCity = value;
  }

  setStage(value) {
    this.stage = value;
  }

  setStageValue(value) {
    this.stageValue = value;
  }

  setSource(value) {
    this.source = value;
  }

  setSourceValue(value) {
    this.sourceValue = value;
  }

  setHasPredictions(value) {
    this.hasPredictions = value;
  }

  setHasPredictionsValue(value) {
    this.hasPredictionsValue = value;
  }

  setHasMissingData(value) {
    this.hasMissingData = value;
  }

  setHasMissingDataValue(value) {
    this.hasMissingDataValue = value;
  }

  setIsClosed(value) {
    this.isClosed = value;
  }

  setIsClosedValue(value) {
    this.isClosedValue = value;
  }

  setIsSpam(value) {
    this.isSpam = value;
  }

  setIsSpamValue(value) {
    this.isSpamValue = value;
  }

  setIsComplete(value) {
    this.isComplete = value;
  }

  setIsCompleteValue(value) {
    this.isCompleteValue = value;
  }

  setIsInInbox(value) {
    this.isInInbox = value;
  }

  setIsInInboxValue(value) {
    this.isInInboxValue = value;
  }

  setIsZombie(value) {
    this.isZombie = value;
  }

  setIsZombieValue(value) {
    this.isZombieValue = value;
  }

  setRequiresMoreInfo(value) {
    this.requiresMoreInfo = value;
  }

  setRequiresMoreInfoValue(value) {
    this.requiresMoreInfoValue = value;
  }

  setRequiresManualHelp(value) {
    this.requiresManualHelp = value;
  }

  setRequiresManualHelpValue(value) {
    this.requiresManualHelpValue = value;
  }

  setMakeContactRequiresAction(value) {
    this.makeContactRequiresAction = value;
  }

  setMakeContactRequiresActionValue(value) {
    this.makeContactRequiresActionValue = value;
  }

  setUnableToContactRequiresAction(value) {
    this.unableToContactRequiresAction = value;
  }

  setUnableToContactRequiresActionValue(value) {
    this.unableToContactRequiresActionValue = value;
  }

  setApiStatus(value) {
    this.apiStatus = value;
  }

  setCurrentWidget(value) {
    this.currentWidget = value;
  }

  setGridView(value) {
    this.gridView = value;
  }

  addClassificationsNot(classification) {
    set(this.classificationsNot, classification);
  }

  removeClassificationNot(classification) {
    remove(this.classificationsNot, classification);
  }

  setClassificationNot(value) {
    this.classificationNot = value;
  }

  addClassifications(classification) {
    set(this.classifications, classification);
  }

  removeClassification(classification) {
    remove(this.classifications, classification);
  }

  addTrafficClassifications(classification) {
    set(this.trafficClassifications, classification);
  }

  removeTrafficClassification(classification) {
    remove(this.trafficClassifications, classification);
  }

  setTrafficClassification(value) {
    this.trafficClassification = value;
  }

  setClassification(value) {
    this.classification = value;
  }

  setTraffic(value) {
    this.traffic = value;
  }

  setTrafficMagnitudeGT(value) {
    this.trafficMagnitudeGT = value;
  }

  setTrafficBetaGT(value) {
    this.trafficBetaGT = value;
  }

  setInvestmentAmount(value) {
    this.investmentAmount = value;
  }

  setInvestmentAmountGT(value) {
    this.investmentAmountGT = value;
  }

  setInvestmentAmountLT(value) {
    this.investmentAmountLT = value;
  }

  setInvestmentAmountDaysGT(value) {
    this.investmentAmountDaysGT = value;
  }

  setInvestmentAmountDaysLT(value) {
    this.investmentAmountDaysLT = value;
  }

  addInvestmentTypes(type) {
    set(this.investmentTypes, type);
  }

  removeInvestmentTypes(type) {
    remove(this.investmentTypes, type);
  }

  setInvestmentType(value) {
    this.investmentType = value;
  }

  setFoundedOn(value) {
    this.foundedOn = value;
  }

  setKeywords(value) {
    this.keywords = value;
  }

  setTermsOperator(value) {
    this.termsOperator = value;
  }

  setFoundedOnGT(value) {
    debugger;
    this.foundedOnGT = value;
  }

  setFoundedOnLT(value) {
    this.foundedOnLT = value;
  }

  addTerms(term) {
    set(this.terms, term);
  }

  removeTerms(term) {
    remove(this.terms, term);
  }

  setOffset(offset) {
    this.offset = offset;
  }

  setTotal(total) {
    this.total = total;
  }

  setOrganizations(organizations) {
    if (organizations === null) this.apiStatus = 200;
    this.organizations = organizations;
  }

  setScores(scores) {
    this.scores = scores;
  }

  updateOrganization(id, organization) {
    const oldOrganization = this.organizations.find(
      (organization) => organization.id === id
    );
    const index = this.organizations.indexOf(oldOrganization);
    set(this.organizations, index, organization);
  }

  setName(name) {
    this.name = name;
  }

  setNameFlag(flag) {
    this.nameFlag = flag;
  }

  setOrderedBy(feature) {
    this.orderedBy = feature;
  }

  setOrder(order) {
    this.order = order;
  }

  setMode(mode) {
    this.mode = mode;
  }

  clearSets() {
    Object.keys(this).forEach((key) => {
      if (typeof this[key]?.clear === "function") {
        this[key].clear();
      }
    });
  }

  investmentTypeAll = computedFn(function investmentTypeAll(array) {
    const internalSorted = [...this.investmentTypes].sort();
    const arraySorted = [...array].sort();

    return (
      this.investmentTypes.size === 0 || isEqual(internalSorted, arraySorted)
    );
  });

  classificationAll = computedFn(function classificationAll(array) {
    const internalSorted = [...this.classifications].sort();
    const arraySorted = [...array].sort();

    return (
      this.classifications.size === 0 || isEqual(internalSorted, arraySorted)
    );
  });

  classificationNotAll = computedFn(function classificationNotAll(array) {
    const internalSorted = [...this.classificationsNot].sort();
    const arraySorted = [...array].sort();

    return (
      this.classificationsNot.size === 0 || isEqual(internalSorted, arraySorted)
    );
  });

  trafficClassificationAll = computedFn(function trafficClassificationAll(
    array
  ) {
    const internalSorted = [...this.trafficClassifications].sort();
    const arraySorted = [...array].sort();

    return (
      this.trafficClassifications.size === 0 ||
      isEqual(internalSorted, arraySorted)
    );
  });

  get pagesNumber() {
    if (this.total === 0) return 0;
    const remainder = this.total % this.size;
    const result = Math.trunc(this.total / this.size);
    return remainder === 0 ? result - 1 : result;
  }

  get previousDisabled() {
    return this.currentPage === 0 ? true : false;
  }

  get nextDisabled() {
    return this.currentPage === this.pagesNumber ? true : false;
  }

  get currentPage() {
    return Math.trunc(this.offset / this.size);
  }

  get bottomPage() {
    if (this.currentPage <= 3) return 0;
    if (this.currentPage === this.pagesNumber) return this.currentPage - 4;
    if (this.currentPage === this.pagesNumber - 1) return this.currentPage - 3;
    else return this.currentPage - 2;
  }

  get topPage() {
    if (this.currentPage <= 1) return 5;
    else return this.currentPage + 3;
  }

  get termsArray() {
    return Array.from(this.terms);
  }

  get investmentTypesArray() {
    return Array.from(this.investmentTypes);
  }

  get classificationsArray() {
    return Array.from(this.classifications);
  }

  get classificationsNotArray() {
    return Array.from(this.classificationsNot);
  }

  get trafficClassificationsArray() {
    return Array.from(this.trafficClassifications);
  }

  get specialsArray() {
    return entries(this.specials);
  }

  get organizationsArray() {
    return toJS(this.organizations);
  }

  get scoresObject() {
    return toJS(this.scores);
  }
}
