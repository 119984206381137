import createAuth0Client from "@auth0/auth0-spa-js";
import { dropdown } from "bootstrap";
import { registerApplication, start } from "single-spa";

import InboxState from "./state/inbox.state";
import OrganizationsState from "./state/organizations.state";
import UserState from "./state/user.state";

import config from "../../../config";
import { inboxStateDefaults, organizationsStateDefaults } from "../../../const";
import mfActiveWhen from "../../../const/routing";

import "../../dig/src/scss/dig.component.scss";
import "./scss/main.scss";

const auth0 = await createAuth0Client({
  domain: config.auth0.url,
  client_id: config.auth0.clientId,
  cacheLocation: "localstorage",
  redirect_uri: `${window.location.protocol}//${window.location.host}/callback`,
  scope: "openid profile email read:user_metadata",
});

const discoverState = new OrganizationsState(organizationsStateDefaults);
const inboxState = new InboxState(inboxStateDefaults);
const incompleteState = new OrganizationsState(organizationsStateDefaults);
const organizationsState = new OrganizationsState(organizationsStateDefaults);
const spamState = new OrganizationsState(organizationsStateDefaults);
const userState = new UserState();

function loadAuth() {
  showLoader();
  return Promise.resolve()
    .then(() => {
      return System.import("@InReachVentures/auth");
    })
    .then((app) => {
      hideLoader();
      return app;
    });
}

function loadNav() {
  showLoader();
  return Promise.resolve()
    .then(() => {
      return System.import("@InReachVentures/nav");
    })
    .then((app) => {
      hideLoader();
      return app;
    });
}

function loadOrganizations() {
  showLoader();
  return Promise.resolve()
    .then(() => {
      return System.import("@InReachVentures/organizations");
    })
    .then((app) => {
      hideLoader();
      return app;
    });
}

function loadDig() {
  showLoader();
  return Promise.resolve()
    .then(() => {
      return System.import("@InReachVentures/dig");
    })
    .then((app) => {
      hideLoader();
      return app;
    });
}

function loadInbox() {
  showLoader();
  return Promise.resolve()
    .then(() => {
      return System.import("@InReachVentures/inbox");
    })
    .then((app) => {
      hideLoader();
      return app;
    });
}

function loadFundingLinkTool() {
  showLoader();
  return Promise.resolve()
    .then(() => {
      return System.import("@InReachVentures/funding-link-tool");
    })
    .then((app) => {
      hideLoader();
      return app;
    });
}

function showLoader() {
  document.getElementById("loader").style.display = "block";
}

function hideLoader() {
  document.getElementById("loader").style.display = "none";
}

start({
  urlRerouteOnly: true,
});

registerApplication({
  name: "@InReachVentures/auth",
  app: loadAuth,
  activeWhen: mfActiveWhen("@InReachVentures/auth"),
  customProps(appName, location) {
    return {
      auth0,
      userState,
    };
  },
});

registerApplication({
  name: "@InReachVentures/nav",
  app: loadNav,
  activeWhen: mfActiveWhen("@InReachVentures/nav"),
  customProps(appName, location) {
    return {
      auth0,
      inboxState,
      userState,
    };
  },
});

registerApplication({
  name: "@InReachVentures/organizations",
  app: loadOrganizations,
  activeWhen: mfActiveWhen("@InReachVentures/organizations"),
  customProps(appName, location) {
    return {
      auth0,
      discoverState,
      inboxState,
      incompleteState,
      organizationsState,
      spamState,
      userState,
    };
  },
});

registerApplication({
  name: "@InReachVentures/dig",
  app: loadDig,
  activeWhen: mfActiveWhen("@InReachVentures/dig"),
  customProps(appName, location) {
    return {
      auth0,
      inboxState,
      userState,
    };
  },
});

registerApplication({
  name: "@InReachVentures/inbox",
  app: loadInbox,
  activeWhen: mfActiveWhen("@InReachVentures/inbox"),
  customProps(appName, location) {
    return {
      auth0,
      inboxState,
      userState,
    };
  },
});

registerApplication({
  name: "@InReachVentures/funding-link-tool",
  app: loadFundingLinkTool,
  activeWhen: mfActiveWhen("@InReachVentures/funding-link-tool"),
  customProps(appName, location) {
    return {
      auth0,
      userState,
    };
  },
});
