import { transform } from "lodash";
import { keys, makeAutoObservable, remove, set, toJS } from "mobx";

export default class InboxState {
  aggregatedInboxes;
  apiStatus;
  counterInbox;
  counters;
  firstRun;
  forceUpdate;
  offset;
  order;
  orderedBy;
  organizations;
  reason;
  size;
  total;
  user;

  constructor(defaultValues) {
    Object.assign(this, defaultValues);
    makeAutoObservable(this);
  }

  setAggregatedInboxes(value) {
    this.aggregatedInboxes = value;
  }

  setReason(value) {
    this.reason = value;
  }

  setUser(value) {
    this.user = value;
  }

  setApiStatus(value) {
    this.apiStatus = value;
  }

  setFirstRun(value) {
    this.firstRun = value;
  }

  setForceUpdate(value) {
    this.forceUpdate = value;
  }

  setCounters(value) {
    value = transform(value, function (result, val, key) {
      result[key.toLowerCase()] = val;
    });
    this.counters = value;
  }

  setCounterInbox(value) {
    this.counterInbox = value;
  }

  incrementCounter(counter) {
    if (counter === "inbox") {
      this.counterInbox++;
      this.total++;
    } else
      this.aggregatedInboxes.find((inbox) =>
        inbox.filters.includes(counter.toLowerCase())
      ).count++;
  }

  decreaseCounter(counter) {
    if (counter === "inbox") {
      this.counterInbox--;
      this.total--;
    } else
      this.aggregatedInboxes.find((inbox) =>
        inbox.filters.includes(counter.toLowerCase())
      ).count--;
  }

  setOffset(offset) {
    this.offset = offset;
  }

  setTotal(total) {
    this.total = total;
  }

  setOrderedBy(feature) {
    this.orderedBy = feature;
  }

  setOrder(order) {
    this.order = order;
  }

  setOrganizations(organizations) {
    if (organizations === null) this.apiStatus = 200;
    this.organizations = organizations;
  }

  addOrganization(id, organization) {
    this.organizations = [...this.organizations, organization];
  }

  removeOrganization(id, organization) {
    const oldOrganization = this.organizations.find(
      (organization) => organization.id === id
    );
    const index = this.organizations.indexOf(oldOrganization);
    remove(this.organizations, index);
  }

  updateOrganization(id, organization) {
    const oldOrganization = this.organizations.find(
      (organization) => organization.id === id
    );
    const index = this.organizations.indexOf(oldOrganization);
    set(this.organizations, index, organization);
  }

  get pagesNumber() {
    if (this.total === 0) return 0;
    const remainder = this.total % this.size;
    const result = Math.trunc(this.total / this.size);
    return remainder === 0 ? result - 1 : result;
  }

  get previousDisabled() {
    return this.currentPage === 0 ? true : false;
  }

  get nextDisabled() {
    return this.currentPage === this.pagesNumber ? true : false;
  }

  get currentPage() {
    return Math.trunc(this.offset / this.size);
  }

  get bottomPage() {
    if (this.currentPage <= 3) return 0;
    if (this.currentPage === this.pagesNumber) return this.currentPage - 4;
    if (this.currentPage === this.pagesNumber - 1) return this.currentPage - 3;
    else return this.currentPage - 2;
  }

  get topPage() {
    if (this.currentPage <= 1) return 5;
    else return this.currentPage + 3;
  }

  get inboxesArray() {
    return toJS(this.counters);
  }

  get organizationsArray() {
    return toJS(this.organizations);
  }

  get countersKeys() {
    return keys(this.counters);
  }
}
